import { Component, ChangeDetectorRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexLegend,
  ApexGrid
} from 'ng-apexcharts';
import { FormGroup } from 'ngx-typesafe-forms';
import { CoreService } from 'src/app/shared/interfaces/core-service.interface';
import { DiagnosisForm } from 'src/app/shared/models/diagnosis.model';
import { DiagnosisService } from 'src/app/shared/services/diagnosis.service/diagnosis.service';
import en from 'apexcharts/dist/locales/en.json';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  grid: ApexGrid
};

@Component({
  selector: 'app-num-of-hits-chart',
  templateUrl: './num-of-hits-chart.component.html',
  styleUrls: ['./num-of-hits-chart.component.scss']
})
export class NumOfHitsChartComponent<T extends DiagnosisForm> implements OnInit {
  @Output() zoomInChange = new EventEmitter<{ start: string; end: string; }>();
  @Output() resetZoomChange = new EventEmitter();

  @Input() diagnosisForm: FormGroup<T>;
  @Input() diagnosisService: DiagnosisService<T>;
  @Input() selectedService: CoreService;
  @Input() isChartOpen: boolean = false;
  @Input() chartOpenHandler: Function;

  @Input() numOfResults: number;
  @Input() numOfSkippedResults: number;
  public chartBackendCalled;

  // chart props
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  private isDarkMode: boolean = localStorage.getItem('theme') === 'dark';
  public isZoomed = false;

  public resetZoom() {
    this.resetZoomChange.emit();

    setTimeout(() => {
      this.isChartOpen = false;
      this.toggleIsOpen();
    }, 500);
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  toggleIsOpen = () => {
    this.chartOpenHandler && this.chartOpenHandler();
    if (!this.isChartOpen) {
      const request = {
        ...this.diagnosisForm.getRawValue(),
        modId: this?.selectedService.modId
      };
      if (!this.chartBackendCalled)
        this.chartBackendCalled = true;
      this.diagnosisService.getChartDataTypes(request).subscribe((data) => {
        const seriesData = ['info', 'error', 'success'].map(type => ({
          name: type,
          data: Object.keys(data).map(key => data[key].y[type] || 0),
          color: getColorByType(type)
        }));

        const offset = calculateOffset(request.to.getTime(), request.from.getTime());
        const categories = Object.keys(data).map((key) => {
          return transformToIsoDateType(data[key].x, offset.offsetInMinutes, offset.offsetInSeconds);
        });

        setTimeout(() => {
          this.chart.updateOptions({
            xaxis: {
              categories: categories
            },
            series:
              seriesData,
            
            grid: {
                show: true,
                borderColor: '#90A4AE',
                strokeDashArray: 1,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                },   
                yaxis: {
                    lines: {
                        show: true
                    }
                }
              }
          });
          this.cdr.detectChanges();
          return data;
        }, 100);

        if (this.chartBackendCalled)
          this.chartBackendCalled = false;
      });
    }
  };

  ngOnInit(): void {
    this.chartOptions = {
      xaxis: {
        type: 'datetime',
        labels: {
          offsetX: 0,
          offsetY: 0,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
            minute: 'HH:mm',
          },
          datetimeUTC: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      series: [
        {
          name: 'success',
          data: []
        },
        {
          name: 'info',
          data: []
        },
        {
          name: 'error',
          data: []
        }
      ],
      chart: {
        stacked: true,
        defaultLocale: 'en',
        locales: [en],
        height: 300,
        type: 'bar',
        toolbar: {
          show: false
        },
        width: '100%',
        fontFamily: 'VWAGTheSans-Regular',
        events: {
          zoomed: (chartContext, { xaxis, yaxis }) => {
            this.isZoomed = true;
            const selectedStartDate = new Date(xaxis.min);
            const selectedEndDate = new Date(xaxis.max);

            const dateNow = new Date();

            const startDate = new Date(Math.min(selectedStartDate.getTime(), dateNow.getTime()));
            const endDate = new Date(Math.min(selectedEndDate.getTime(), dateNow.getTime()));
            
            this.zoomInChange.emit({
              start: startDate.toISOString(),
              end: endDate.toISOString()
            });

            if (!this.chartBackendCalled)
              this.chartBackendCalled = true;

            setTimeout(() => {
              this.isChartOpen = false;
              this.toggleIsOpen();
              this.isChartOpen = true;

              if (this.chartBackendCalled) {
                this.chartBackendCalled = false;
                this.cdr.detectChanges();
              }
            }, 500);
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: "97%"
        }
      },
      title: {
        text: 'Num. of hits',
        align: 'center'
      },
      fill: {
        colors: this.isDarkMode ? ['#349CA0'] : ['#004666']
      },
      dataLabels: {
        enabled: false
      }
    };
  }
}

const getColorByType = (type) => {
  return type === 'success'
    ? '#008000'
    : type === 'error'
      ? '#da0c1fff'
      : '#d3caca'
}

const transformToIsoDateType = (dateAsString: string, minutes: number, seconds: number): string => {
  const dt = new Date(dateAsString);
  dt.setMinutes(dt.getMinutes() + minutes);
  dt.setSeconds(seconds);
  return dt.toISOString();
};

function calculateOffset(endTime: number, startTime: number) {
  const diffInMillis = endTime - startTime;
  const diffInHours = diffInMillis/3600000;

  let offsetInMinutes = 30;
  let offsetInSeconds = 0;

  if (diffInHours < 12) {
    offsetInMinutes = 15;
  }
  if (diffInHours < 6) {
    offsetInMinutes = 7;
    offsetInSeconds = 30;
  }
  if (diffInHours < 3) {
    offsetInMinutes = 5;
    offsetInSeconds = 0;
  }
  if (diffInHours < 1) {
    offsetInMinutes = 2;
    offsetInSeconds = 28;
  }
  return { offsetInMinutes, offsetInSeconds };
}

